@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/contacts.css";
@import "./assets/css/proposals.css";

@layer base {
    a.link {
        @apply text-purple-700 font-semibold underline;
    }

    .visible-true {
        display: block;
    }
    .visible-false {
        display: none !important;
    }
    .selected-true {
        @apply bg-purple-200;
    }

    .statuslabel {
        @apply inline-block px-2 rounded-sm text-micro font-semibold text-white uppercase bg-gray-600;
    }
    .statuslabel-success {
        @apply bg-green-300 text-green-900;
    }
    .statuslabel-error {
        @apply bg-red-300 text-red-900;
    }
    .statuslabel-warning {
        @apply bg-yellow-300 text-yellow-900;
    }

    /* DROPDOWN MENUS */
    .dropdown__container {
        @apply px-4 py-2 absolute top-8 right-0 shadow-md rounded-sm text-xs;
    }
    .dropdown__container > li {
        @apply py-2 border-b border-gray-200 last:border-b-0;
    }
    .dropdown__container-white {
        @apply text-gray-500 bg-white;
    }
}

@layer components {

    /* CONTAINERS */
    .appcontainer {
        @apply container mx-auto max-w-screen-xl;
    }
    .breadcrumbs__container {
        @apply mt-4 mb-4;
    }

    /* BACKEND */
    #backend__container {
        @apply grid grid-cols-4 lg:grid-cols-11 space-x-2 min-h-screen;
    }
    .backend__sidebar {
        @apply hidden md:block min-h-screen bg-gray-800 p-4 col-span-1 lg:col-span-2;
    }
    .backend__content {
        @apply col-span-4 md:col-span-3 lg:col-span-9 p-4 md:p-8;
    }
    .backend__header {
        @apply flex flex-row items-start justify-between space-x-4 mb-4 md:mb-8;
    }

    /* BACKEND SIDEBAR */
    .sidebar__container {
        position: fixed;
        left: 0;
        top: 0;
        transform: translate3d(-100vw,0,0);
        transition: transform .3s cubic-bezier(0, .52, 0, 1);
        width: 100vw;
        min-height:100vh;
        @apply md:hidden p-8 pt-16 bg-gray-800 flex flex-col items-start justify-start z-50;
    }
    .sidebar__container.visible-false {
        transform: translate3d(-100vw, 0, 0);
    }
    .sidebar__container.visible-true {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
    }
    .sidebar__container > li, .backend__sidebar > li {
        @apply flex flex-row items-center justify-start border-b border-gray-700 last:border-b-0 relative;
    }
    .sidebar__container > li, .backend__sidebar > li > a {
        @apply flex flex-row w-full items-center justify-start py-6 px-4 text-gray-400 cursor-pointer hover:text-white hover:bg-gray-700 transition-all text-tiny uppercase font-semibold md:py-4;
    }
    .sidebar__container > li > a > svg, .backend__sidebar > li > a > svg {
        @apply mr-2 w-4 h-4 inline-block;
    }

    /* BACKEND */
    .settings__group {
        @apply grid grid-cols-1 md:grid-cols-2 space-y-2 justify-start items-center md:space-x-8 border-b border-gray-200 last:border-b-0 py-8;
        @apply items-start;
    }
    .settings__control label {
        @apply text-gray-500 text-xs font-semibold;
    }

    .settings__control input:disabled {
        @apply bg-gray-100 text-gray-300;
    }

    .belabel__description h3, .form__label {
        @apply text-gray-700 text-sm lg:text-base font-semibold leading-4;
    }
    .belabel__description p {
        @apply text-gray-400 text-tiny lg:text-xs font-normal;
    }

    /* BACKEND TABLES */
    .backend__table {
        @apply text-left w-full text-xs text-gray-600;
    }
    .backend__table thead {
        @apply border-b-2 border-gray-900 font-semibold text-sm;
    }
    .backend__table thead > tr > td {
        @apply py-2 mb-4;
    }

    .backend__table tbody > tr {
        @apply border-b border-gray-200 last:border-b-0;
    }

    .backend__table tbody > tr > td {
        @apply py-2;
    }


    /* NAVBARS */
    .nav-text-item {
        @apply px-3 py-3 uppercase font-semibold text-gray-700;
    }
    .dropdown:hover .dropdown-menu {
        display: block;
    }
    #brand-logo > img {
        @apply h-8 py-1 w-auto;
    }
    #navbar-dropdown:checked ~ #navbar-menu {
        display:block;
    }

    /* BUTTONS */
    .default-button {
        @apply px-4 py-2 rounded-sm bg-purple-800 text-tiny font-medium text-white uppercase flex flex-row items-center justify-between;
    }
    .muted-button {
        @apply px-4 py-2 rounded-sm bg-gray-700 text-tiny font-medium text-white uppercase flex flex-row items-center justify-between;
    }
    .disabled-button {
        @apply px-4 py-2 rounded-sm bg-gray-300 text-tiny font-medium text-white uppercase flex flex-row items-center justify-between;
    }

    /* VIDEO PLAYER */
    .videoplayer__wrapper {
        position: relative;
        padding-top: 56.25%;
    }
    .videoplayer {
        position:absolute;
        top:0;
        left:0;
    }

    /* FORMS */
    input[type=checkbox]:focus, input[type=text]:focus, textarea {
        @apply focus:ring-transparent focus:ring-offset-transparent focus:ring-offset-0 focus:outline-none;
    }

    .form__dropdown {
        @apply inline-block w-full border-gray-200 text-gray-700 text-xs;
    }

    .form-field {
        @apply bg-white px-3 py-2 rounded-sm border border-gray-200 text-xs text-gray-700 placeholder-gray-300 focus:outline-none w-full;
    }
    .form-field.with-icon {
        @apply pl-8;
    }

    .form__label--small {
        @apply text-sm;
    }

    .form__optiongroup {
        @apply flex flex-row items-center justify-start space-x-4 mb-4;
    }

    .field__container {
        @apply mb-0;
    }

    /* HEROICONS */
    .light-icon {
        @apply w-4 h-4 text-white;
    }
    .dark-icon {
        @apply w-4 h-4 text-gray-700;
    }
    .mid-icon {
        @apply w-4 h-4 text-gray-400;
    }
    .red-icon {
        @apply w-4 h-4 text-red-700;
    }
    .green-icon {
        @apply w-4 h-4 text-green-700;
    }

    /* PRODUCT CARDS */
    .productcard {
        @apply rounded-sm shadow-sm hover:shadow-lg border border-gray-200;
    }
    .productcard__content {
        @apply p-4;
    }
    .productcard__title {
        @apply text-gray-800 font-bold text-base;
    }
    .productcard__metadata {
        @apply mt-3;
    }
    .productcard__label {
        @apply text-tiny leading-3 text-gray-500 font-bold uppercase;
    }
    .productcard__value {
        @apply text-tiny text-gray-400;
    }

    /* PAGINATION */
    .pagination {
        @apply hidden md:flex md:justify-end list-none mt-8 mb-4 text-xs text-gray-400 font-medium;
    }
    .pagination > li {
        @apply py-2 px-4 bg-white border border-gray-200 ml-0 md:ml-2 rounded hover:bg-purple-700 hover:border-purple-700 hover:text-white;
    }

    /* ALERTS */
    .alert__container {
        @apply flex flex-row space-x-4 justify-start items-center my-2 p-4 rounded-sm border;
    }
    .alert__icon, .alert__close {
        @apply flex-grow-0;
    }
    .alert__content {
        @apply flex-1 text-xs;
    }
        .alert__title {
            @apply font-bold;
        }
        .alert__text {

        }
        .alert__actions {
            @apply flex flex-row space-x-2 justify-end items-center;
        }

    /* TABS */
    .tabs__container {
        @apply flex flex-row text-left uppercase text-micro md:text-tiny font-medium min-w-full;
    }
    .tabs__tab {
        @apply px-3 py-1 border border-t-0 border-l-0 first:border-l border-gray-200  text-gray-400 cursor-pointer;
    }

    .tabs__tab-active {
        @apply text-purple-700 font-semibold;
    }



}

@layer utilities {

    /* INTERACTIONS */
    .interaction__container {
        @apply flex flex-row justify-start items-start text-xs;
    }
    .interaction__container > div {
        @apply border-r border-gray-200;
    }

    .interactionItem__container {
        @apply p-2 flex flex-row justify-between items-center space-x-2 cursor-pointer border-b border-gray-200;
    }
    .interactionItem__container:hover {
        @apply bg-purple-50;
    }

        .interactionItem__data{
            @apply flex flex-row space-x-2 items-center flex-shrink-0;
        }
            .interactionItem__image {
                @apply w-14;
            }
            .interactionItem__data h3 {
                @apply text-gray-900 font-bold;
            }
            .interactionItem__data h4 {
                @apply text-gray-500 font-bold;
            }
            .interactionItem__data p {
                @apply text-gray-600 mb-1;
            }
            .interactionItem__data span {
                @apply uppercase text-gray-400 text-tiny;
            }

        .interactionItem__actions {
            @apply flex flex-row justify-between items-center;
        }
            .interactionItem__count {
                @apply p-2 rounded-full w-8 h-8 bg-purple-500 text-white text-tiny text-center font-semibold;
            }
            .interactionItem__count-text {
                @apply text-purple-500 text-tiny font-semibold;
            }


    .interactions__orders, .interactions__events {
        @apply p-4;
    }
    .interactions__chats {
        @apply flex flex-row items-start justify-center bg-gray-100 p-1 min-h-full object-cover relative w-full;
        min-height: 75vh;
    }

}