@layer components {
    .contacts__panel {
        @apply mt-4;
    }
        .contacts__panel-mobile {
            @apply md:hidden;
        }
        .contacts__panel-desktop {
            @apply hidden md:block;
        }

        .contacts__filters-desktop {

        }
        .contacts__filters-mobile {
            @apply flex flex-row items-center justify-between space-x-2 mb-4 md:hidden;
        }
            .contacts__mobileswitcher {
                @apply md:hidden;
            }

        .contacts__cardgrid {
            @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
        }
            .contacts__card {
                @apply p-6 m-2 rounded-sm shadow-md cursor-pointer;
            }
                .contacts__card h1 {
                    @apply font-bold text-base text-black;
                }
                .contacts__card h3 {
                    @apply font-normal text-sm text-gray-500 leading-3 mb-4;
                }
            .contacts__card-selected {
                @apply ring-2 ring-purple-700;
            }

        .contacts__assignpanel {
            @apply fixed -bottom-96 left-0 opacity-0 flex flex-row space-x-2 justify-between items-end p-4 w-screen bg-purple-700 transition-all duration-500 md:hidden;
        }
        .contacts__assignpanel-visible {
            @apply opacity-100 bottom-0 left-0;
        }

    .contacts__tabs {
        @apply grid grid-cols-4;
    }
        .contacts__tab {
            @apply p-2 text-center border border-gray-200 text-sm text-gray-600;
        }
        .contacts__tab-selected {
            border-top: 3px solid rgba(109,40,217,1);
            @apply border-b-0 font-bold;
        }

    .filterbar__inlinefilters {
        @apply flex flex-row items-center justify-start space-x-2;
    }
        .filterbar__inlinefilters > span {
            @apply text-tiny font-semibold text-gray-700;
        }

    .badge-link {
        @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-blue-100 bg-blue-700 rounded;
    }

    .badge-danger {
        @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-700 rounded;
    }

    .badge-success {
        @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-green-100 bg-green-700 rounded;
    }

    .badge-info {
        @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-indigo-700 rounded;
    }

    .badge-gray {
        @apply inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-gray-100 bg-gray-700 rounded;
    }


}
