@layer components {

    .mobilefilters--close {
        @apply absolute right-4 top-4 w-6 h-6 text-gray-600 cursor-pointer;
    }

    .proposals__main {
        @apply grid grid-cols-3 h-auto;
    }

    /* CONTENT */
    .proposals__content {
        @apply col-span-4 md:col-span-3 md:pr-8 md:border-r md:border-gray-200;
    }
        .proposals__settings {
            @apply flex flex-col md:flex-row justify-start md:space-x-8;
        }

        .proposals__products {
            @apply grid grid-cols-3 gap-4;
        }
            .proposals__filters{
                @apply col-span-1 bg-gray-100 min-h-screen hidden md:block;
            }

            .proposals__filters-mobile {
                @apply w-full h-screen fixed top-0 left-0 z-50 block md:hidden bg-gray-100;
            }

            .proposals__productgrid {
                @apply col-span-3 md:col-span-2 grid grid-cols-3 gap-2 md:grid-cols-3 content-start;
            }
                .proposals__product {
                    @apply p-2 cursor-pointer;
                }
                    .proposals__product p {
                        @apply text-tiny text-gray-500 text-center;
                    }
                    .proposals__product img {

                    }
                .proposals__product-selected {
                    @apply bg-purple-50;
                }
                .proposals__product-selected p {
                    @apply font-semibold;
                }


    .proposals__selectedproducts {
        @apply divide-y divide-gray-100;
    }

    .proposals__selectedproduct {
        @apply flex flex-row justify-start md:justify-between items-center space-x-4 md:space-x-2 py-2;
    }
        .proposals__selectedproduct img {
            @apply w-12 h-auto;
        }
        .proposals__selectedproduct p {
            @apply text-xs text-gray-600;
        }

    /* SIDEBAR */
    .proposals__sidebar {
        @apply hidden md:block md:col-span-1 md:pl-8;
    }
        .proposals__actions {
            @apply pb-8 mb-4 border-b border-gray-200 space-y-4;
        }

    /* ACTIONPANEL MOBILE */
    .proposals__actionpanel {
        @apply fixed bottom-0 w-full flex flex-row justify-around items-center p-4 bg-white bg-opacity-70 md:hidden z-10;
    }
    .proposals__selectionpopup {
        @apply fixed bottom-16 right-6 max-h-96 overflow-y-scroll p-4 bg-white shadow-2xl rounded divide-y divide-gray-100;
    }
        .proposals__selectionpopup span {
            @apply text-purple-700 font-semibold text-sm;
        }

}